import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/sasano_arutoki');
  }, [navigate]);

  return <></>;
}

export default Home;