import {PersonalData} from '../../pages/NameCards/data';

export default {
  title: '［ 無情な腰内肉収穫機 ］',
  name: '笹野或齋',
  nameInOtherLang: '(ササノ アルトキ / Sasano Arutoki)',
  social: [
    { type: 'facebook', uri: 'https://www.facebook.com/sasano.arutoki/' },
    { type: 'bluesky', uri: 'https://bsky.app/profile/sasano-arutoki.bsky.social' },
    { type: 'x', uri: 'https://x.com/sasano_arutoki' }
  ],
  bio: (
    <>
      中文 （正體、简体）/ English (US, UK)
      <br />
      30、INFP、小熊貓、菜雞全端工程師
      <br />
      胖獸甲、社恐、隨和、強迫症
    </>
  ),
} as PersonalData;