import './index.css';

const NotFound = () => {
  return <>
    <div className={'imgContainer'}>
      <img
        className={'image'}
        src={'./images/404.png'}
        alt={'HTTP 404. Source not found.'}
      />
    </div>
  </>;
}

export default NotFound;