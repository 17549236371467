import {SocialMedia, SocialMediaType} from '../../data';
import {JSX} from "react";

const SocialMediaButtons = ({socialMedias}: {socialMedias?: SocialMedia[]}) => {
  const socialMediaTypes: SocialMediaType[] = [
    'bluesky',
    'discord',
    'facebook',
    'instagram',
    'plurk',
    'steam',
    'x',
  ];

  const socialMediaButton: Record<string, JSX.Element> = {
    bluesky: <img alt={'bluesky icon'} src={'images/icons/bluesky.webp'}/>,
    discord: <img alt={'discord icon'} src={'images/icons/discord.webp'}/>,
    facebook: <img alt={'facebook icon'} src={'images/icons/facebook.webp'}/>,
    instagram: <img alt={'ig icon'} src={'images/icons/instagram.webp'}/>,
    plurk: <img alt={'plurk icon'} src={'images/icons/plurk.webp'}/>,
    steam: <img alt={'steam icon'} src={'images/icons/steam.webp'}/>,
    x: <img alt={'x icon'} src={'images/icons/x.webp'}/>,
  };

  return (
    <>
      {!!socialMedias && socialMedias.map((socialMedia: SocialMedia) => {
        if(socialMediaTypes.includes(socialMedia.type as SocialMediaType)){
          return (
            <>
              <a href={socialMedia.uri || ''}>
                {!!socialMedia.type && socialMediaButton[socialMedia.type as string]}
              </a>
            </>
          );
        }else{
          return null;
        }
      })}
    </>
  );
}

export default SocialMediaButtons;