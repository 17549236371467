import SocialMediaButtons from './components/socialMediaButtons';
import {useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {PersonalData} from './data';
import customize from '../../storage/customize';
import './index.css';

const NameCards = () => {
  const { name } = useParams<{ name: string }>();
  const navigate = useNavigate();
  const [personalData] = useState<PersonalData>(!!name ? customize[name]: {});

  useEffect(() => {
    if(!personalData){
      navigate('/404');
    }

    document.title = `${personalData.title} ${personalData.name}`;
  }, [personalData, navigate]);

  if(!personalData) return <>Loading</>;

  return (
    <>
      <div id={'background'} style={{backgroundImage: `url("./images/backgrounds/${name}.webp")`}}>
        <div id={'card'}>
          <div>
            <img id={'avatar'} alt={'avatar'} src={`images/avatars/${name}.webp`}/>
          </div>
          <div id={'name'}>
            {personalData?.title}
            <br/>
            {personalData?.name}
            <br/>
            {personalData?.nameInOtherLang}
          </div>
          <div id={'buttons'}>
            <SocialMediaButtons socialMedias={personalData?.social}/>
          </div>
          <div id={'bio'}>
            {personalData?.bio}
          </div>
          <div id={'footer'}>
            <img id={'powered_by'} src={'./favicon.ico'} alt={'icon'} />
            Powered By Snat Studio
          </div>
        </div>
      </div>
    </>
  );
}

export default NameCards;