import React from 'react';
import {Route, Routes} from 'react-router-dom';
import NotFound from './components/NotFound';
import NameCards from './pages/NameCards';
import './App.css';
import Home from "./pages/Home";

function App() {
  return (
    <div className={'App'}>
      <Routes>
        <Route element={<Home />} path={'/'} />
        <Route element={<NotFound />} path={'/404'} />
        <Route element={<NameCards />} path={'/:name'} />
        <Route element={<NotFound />} path={'/*'} />
      </Routes>
    </div>
  );
}

export default App;
