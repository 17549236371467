import {PersonalData} from '../../pages/NameCards/data';

export default {
  title: '［風暴的締造者］',
  name: '久野兎太',
  nameInOtherLang: '(ヒサノ ウサタ / Hisano Usata)',
  social: [
    { type: 'facebook', uri: 'https://www.facebook.com/hisano.usatai' },
    { type: 'facebook', uri: 'https://www.facebook.com/profile.php?id=100001443662810' },
    { type: 'instagram', uri: 'https://www.instagram.com/rabbit521069' },
  ],
  bio: (
    <>
      中文 / 微台語 / 不會英文
      <br />
      28、兔子/兔兔、社區防衛小尖兵
      <br />
      胖獸甲、隨和、資深肥宅
      <br />
      歡迎聊天
    </>
  ),
} as PersonalData;